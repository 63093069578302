/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import {
  propTypes,
  SHOW_SEARCH_BAR,
  SIGNUP_TAB_DETAIL,
  SIGNUP_TAB_COMPLETED,
  BUSINESS_LISTING_TYPE_PUBLISHED,
} from '../../../../util/types';
import { ensureCurrentUser, getBusinessListingParams } from '../../../../util/data';

import {
  Button,
  NamedLink,
  AvatarLarge,
  InlineTextButton,
  // NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUser,
    businessListing,
    // notificationCount,
    // currentUserHasListings,
    onLogout,
    onResendVerificationEmail,
    sendVerificationEmailInProgress
  } = props;

  const user = ensureCurrentUser(currentUser);
  const { emailVerified, profile } = (user && user.id && user.attributes) || {};
  const { firstName, publicData, protectedData } = profile || {};
  const { isAdmin } = publicData || {};
  const {
    adminPanel,
    signUpCompleted,
    businessListingId = false,
    businessListingPublished = false,
  } = protectedData || {};
  const { pageCompleted } = (businessListing && businessListing.id && businessListing.attributes.privateData) || {};

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
      </div>
    );
  }

  // const notificationCountBadge =
  //   notificationCount > 0 ? (
  //     <NotificationBadge className={css.notificationBadge} count={notificationCount} />
  //   ) : null;

  const displayName = firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const editListingParams = protectedData && getBusinessListingParams(currentUser);
  // const showSearch = algoliaSearch && SHOW_SEARCH_BAR.includes(currentPage);

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        {isAuthenticated
          ? !signUpCompleted || (signUpCompleted && signUpCompleted != SIGNUP_TAB_COMPLETED)
            ? <NamedLink className={css.navigationLink} name="SignUpStepsPage" params={{ tab: "detail" }} >
              <span className={css.createListing}>
                <FormattedMessage id={signUpCompleted == SIGNUP_TAB_DETAIL
                  ? 'TopbarDesktop.profileDetail'
                  : 'TopbarDesktop.profileReference'} />
              </span>
            </NamedLink>
            : signUpCompleted && signUpCompleted == SIGNUP_TAB_COMPLETED
              ? emailVerified
                ? businessListingId
                  ? businessListingPublished == BUSINESS_LISTING_TYPE_PUBLISHED
                    ? <ul>
                      <h2>
                        <FormattedMessage id="TopbarDesktop.dashboard" />
                      </h2>
                      <li>
                        <NamedLink
                          className={css.navigationLink}
                          name="DashboardBasePage"
                        >
                          <span className={css.createListing}>
                            <FormattedMessage id="TopbarDesktop.bidderDashboard" />
                          </span>
                        </NamedLink>
                      </li>
                      <li>
                        <NamedLink
                          className={css.navigationLink}
                          name="ProviderDashBoardPage"
                        >
                          <span className={css.createListing}>
                            <FormattedMessage id="TopbarDesktop.providerDashboard" />
                          </span>
                        </NamedLink>
                      </li>
                      {isAdmin
                        ? <li>
                          <NamedLink
                            className={css.navigationLink}
                            name="DashboardPage"
                            params={{ tab: 'admin' }}
                          >
                            <span className={css.createListing}>
                              <FormattedMessage id="TopbarDesktop.adminDashboardPage" />
                            </span>
                          </NamedLink>
                        </li>
                        : null}
                    </ul>
                    : pageCompleted == 'details'
                      ? <NamedLink className={css.navigationLink} name="EditListingPage" params={editListingParams}>
                        <span className={css.createListing}>
                          <FormattedMessage id={"TopbarDesktop.businessDetail"} />
                        </span>
                      </NamedLink>
                      : <NamedLink className={css.navigationLink} name="EditListingPage" params={editListingParams}>
                        <span className={css.createListing}>
                          <FormattedMessage id={"TopbarDesktop.businessFinal"} />
                        </span>
                      </NamedLink>
                  : <NamedLink className={css.navigationLink} name="EditListingPage" params={editListingParams}>
                    <span className={css.createListing}>
                      <FormattedMessage id={"TopbarDesktop.businessAdd"} />
                    </span>
                  </NamedLink>
                : <Button
                  className={css.buttonLink}
                  onClick={onResendVerificationEmail}
                  inProgress={sendVerificationEmailInProgress}
                >
                  <span className={css.createListing}>
                    <FormattedMessage id={"TopbarDesktop.verifyEmail"} />
                  </span>
                </Button>
              : null
          : null}

        {isAdmin || adminPanel
          ? <NamedLink
            className={classNames(css.navigationLink, currentPageClass('AdminDashboardPage'))}
            name="AdminDashboardBasePage"
          >
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </NamedLink>
          : null}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.createRFP" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  // currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
