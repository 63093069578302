import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { currentUser, pageAssetsData, inProgress, error } = props;

  const { email, profile } = (currentUser && currentUser.id && currentUser.attributes) || {};
  const { firstName, lastName, publicData } = profile || {};
  const { tag = '' } = publicData || {};

  useEffect(() => {
    typeof window != "undefined" && pendo();
  }, [currentUser]);

  const pendo = () => {
    const ACCOUNT_ID = process.env.REACT_APP_PENDO_ACCOUNT_ID;

    if (firstName && lastName && window.pendo && typeof window.pendo.initialize == 'function') {
      window.pendo.initialize({
        visitor: {
          id: currentUser.id.uuid, // Required if user is logged in
          email,        // Recommended if using Pendo Feedback, or NPS Email
          full_name: (firstName + ' ' + lastName),   // Recommended if using Pendo Feedback
          role: tag, // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },
        account: {
          id: ACCOUNT_ID // Highly recommended, required if using Pendo Feedback
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        }
      });
    }
  }

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const { currentUser } = state.user;

  return { currentUser, pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
