import {
  crudBusinesses,
  crudCategories,
  crudEvents,
  crudEventTypes,
  crudSettings,
  crudTransactions,
  crudWeights,
  listCollections
} from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_COLLECTIONS_REQUEST = 'app/AdminDashboardPage/FETCH_COLLECTIONS_REQUEST';
export const FETCH_COLLECTIONS_SUCCESS = 'app/AdminDashboardPage/FETCH_COLLECTIONS_SUCCESS';
export const FETCH_COLLECTIONS_ERROR = 'app/AdminDashboardPage/FETCH_COLLECTIONS_ERROR';

export const FETCH_COLLECTION_REQUEST = 'app/AdminDashboardPage/FETCH_COLLECTION_REQUEST';
export const FETCH_COLLECTION_SUCCESS = 'app/AdminDashboardPage/FETCH_COLLECTION_SUCCESS';
export const FETCH_COLLECTION_ERROR = 'app/AdminDashboardPage/FETCH_COLLECTION_ERROR';

export const CREATE_COLLECTION_REQUEST = 'app/AdminDashboardPage/CREATE_COLLECTION_REQUEST';
export const CREATE_COLLECTION_SUCCESS = 'app/AdminDashboardPage/CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_ERROR = 'app/AdminDashboardPage/CREATE_COLLECTION_ERROR';

export const UPDATE_COLLECTION_REQUEST = 'app/AdminDashboardPage/UPDATE_COLLECTION_REQUEST';
export const UPDATE_COLLECTION_SUCCESS = 'app/AdminDashboardPage/UPDATE_COLLECTION_SUCCESS';
export const UPDATE_COLLECTION_ERROR = 'app/AdminDashboardPage/UPDATE_COLLECTION_ERROR';


// ================ Reducer ================ //
const initialState = {
  fetchCollectionInProgress: false,
  fetchCollectionError: null,
  fetchInProgress: false,
  fetchError: null,
  createInProgress: false,
  createError: null,
  updateInProgress: false,
  updateError: null,
  pagination: null,
  collections: [],
  data: [],
};

export default function AdminDashboardPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_COLLECTIONS_REQUEST:
      return { ...state, fetchCollectionInProgress: true, fetchCollectionError: null };
    case FETCH_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        fetchCollectionInProgress: false,
        collections: payload,
      };
    }
    case FETCH_COLLECTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchCollectionInProgress: false, fetchCollectionError: payload };

    case FETCH_COLLECTION_REQUEST:
      return { ...state, fetchInProgress: true, pagination: null, data: [], fetchOrdersOrSalesError: null };
    case FETCH_COLLECTION_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        data: payload.data,
        pagination: payload.meta,
      };
    }
    case FETCH_COLLECTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case CREATE_COLLECTION_REQUEST:
      return { ...state, createInProgress: true, createError: null };
    case CREATE_COLLECTION_SUCCESS: {
      return {
        ...state,
        createInProgress: false,
        data: payload,
      };
    }
    case CREATE_COLLECTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, createInProgress: false, createError: payload };

    case UPDATE_COLLECTION_REQUEST:
      return { ...state, updateInProgress: true, updateError: null };
    case UPDATE_COLLECTION_SUCCESS: {
      return {
        ...state,
        updateInProgress: false,
        data: payload,
      };
    }
    case UPDATE_COLLECTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, updateInProgress: false, updateError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchCollectionsRequest = () => ({ type: FETCH_COLLECTIONS_REQUEST });
const fetchCollectionsSuccess = response => ({
  type: FETCH_COLLECTIONS_SUCCESS,
  payload: response,
});
const fetchCollectionsError = e => ({
  error: true,
  payload: e,
  type: FETCH_COLLECTIONS_ERROR,
});

const fetchCollectionRequest = () => ({ type: FETCH_COLLECTION_REQUEST });
const fetchCollectionSuccess = response => ({
  type: FETCH_COLLECTION_SUCCESS,
  payload: response,
});
const fetchCollectionError = e => ({
  error: true,
  payload: e,
  type: FETCH_COLLECTION_ERROR,
});

const updateCollectionRequest = () => ({ type: UPDATE_COLLECTION_REQUEST });
const updateCollectionSuccess = response => ({
  type: UPDATE_COLLECTION_SUCCESS,
  payload: response,
});
const updateCollectionError = e => ({
  error: true,
  payload: e,
  type: UPDATE_COLLECTION_ERROR,
});

const createCollectionRequest = () => ({ type: CREATE_COLLECTION_REQUEST });
const createCollectionSuccess = response => ({
  type: CREATE_COLLECTION_SUCCESS,
  payload: response,
});
const createCollectionError = e => ({
  error: true,
  payload: e,
  type: CREATE_COLLECTION_ERROR,
});

// ================ Thunks ================ //

const RESULT_PAGE_SIZE = 20;

export const loadData = (params, search, request = true) => (dispatch, getState, sdk) => {
  const { tab } = params;
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const perPage = RESULT_PAGE_SIZE;

  const query = {};
  if (queryParams.search) {
    const regex = queryParams.search; //new RegExp(queryParams.search, 'i');
    console.log(queryParams.search, '**** **** => queryParams.search', regex);
    Object.assign(query, {
      $or: [
        { title: { $regex: regex } },
        { id: { $regex: regex } },
        { parentId: { $regex: regex } }
      ]
    });
  }
  const options = {
    sort: (queryParams.sort == '-createdAt'
      ? { createdAt: 1 }
      : queryParams.sort == '-updatedAt'
        ? { updatedAt: -1 }
        : queryParams.sort == '-deletedAt'
          ? { updatedAt: -1 }
          : { createdAt: -1 }),
    skip: (page > 1 ? ((page - 1) * perPage) : 0),
    limit: perPage,
  };

  if (request) {
    dispatch(fetchCollectionsRequest());
    dispatch(fetchCollectionRequest());
  }

  return listCollections({})
    .then(collections => {
      if (collections.data && collections.data.length) {
        const filteredCollections = collections.data.filter(d => ['weights', 'settings', 'eventtypes', 'categories', 'businesses', 'events', 'transactions'].includes(d));
        dispatch(fetchCollectionsSuccess(filteredCollections));
      }
      switch (tab) {
        case 'weights':
          crudWeights({})
            .then(response => {
              if (response && response.data) {
                Object.assign(response, { data: response.data.map(r => ({ ...r, ...r.weight })) });
              }
              return dispatch(fetchCollectionSuccess(response));
            })
            .catch(err => dispatch(fetchCollectionError(err)))
          break;
        case 'settings':
          crudSettings({})
            .then(response => dispatch(fetchCollectionSuccess(response)))
            .catch(err => dispatch(fetchCollectionError(err)))
          break;
        case 'events':
          crudEvents({ query, options })
            .then(response => dispatch(fetchCollectionSuccess(response)))
            .catch(err => dispatch(fetchCollectionError(err)))
          break;
        case 'eventtypes':
          crudEventTypes({ query, options })
            .then(response => dispatch(fetchCollectionSuccess(response)))
            .catch(err => dispatch(fetchCollectionError(err)))
          break;
        case 'categories':
          crudCategories({ query, options })
            .then(response => dispatch(fetchCollectionSuccess(response)))
            .catch(err => dispatch(fetchCollectionError(err)))
          break;
        case 'businesses':
          crudBusinesses({ query, options })
            .then(response => dispatch(fetchCollectionSuccess(response)))
            .catch(err => dispatch(fetchCollectionError(err)))
          break;
        case 'transactions':
          crudTransactions({ query, options })
            .then(response => dispatch(fetchCollectionSuccess(response)))
            .catch(err => dispatch(fetchCollectionError(err)))
          break;
        default:

          break;
      }
      return collections;
    })
    .catch(e => {
      dispatch(fetchCollectionsError(storableError(e)));
      throw e;
    });
};
