import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { string, func, bool } from 'prop-types';

import { useConfiguration } from '../../context/configurationContext';

import { LISTING_TYPE_BUSINESS, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { createSlug } from '../../util/urlHelpers';
import { boothTypes } from '../../config/configListing';
import { displayPrice } from '../../util/configHelpers';
import { ensureListing, ensureUser } from '../../util/data';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import businessDefaultImage from '../../assets/sitelogo.png';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

const filterClasses = {
  signedUp: css.signedUp, // No shadow
  boothConfigured: css.boothConfigured, // Black shadow
  onboarded: css.onboarded, // Green shadow
  activeEngaged: css.activeEngaged, // Purple shadow
  tradeShow: css.tradeShow, // Blue shadow
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    handleHover,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;

  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { attributes, profileImage } = currentListing.author || {};
  const { tag, rfpBids = 0, acceptedBids = 0, ratings = 0, totalRatings = 0 } = attributes.profile.publicData || {};
  const { title, price, createdAt, publicData } = currentListing.attributes;
  const {
    city,
    region,
    postcode,
    dbaName,
    address1,
    boothType,
    boothTypeChilds,
    companyName,
    stripeOnBoard,
    typeOfListing
  } = publicData || {};

  const getBoothFilter = boothTypes.filter((st) => boothType && boothType.length && boothType.includes(st.key));
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage = profileImage
    ? profileImage
    : currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]
      : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onClick: () => setActiveListing(currentListing.id),
      // onMouseLeave: () => setActiveListing(null),
    }
    : null;
  const authorId = listing?.author?.id?.uuid;
  const isBusiness = typeOfListing == LISTING_TYPE_BUSINESS && authorId;

  // Determine which class to apply based on conditions
  let appliedClass = '';

  if (tag == 'Trade Show') {
    appliedClass = filterClasses.tradeShow; // Filter 5
  } else if ((rfpBids > 0) || (ratings > 0)) {
    appliedClass = filterClasses.activeEngaged; // Filter 4
  } else if (stripeOnBoard) {
    appliedClass = filterClasses.onboarded; // Filter 3
  } else if (boothType && boothType.length) {
    appliedClass = filterClasses.boothConfigured; // Filter 2
  } else {
    appliedClass = filterClasses.signedUp; // Filter 1
  }
  
  const classes = classNames(rootClassName || css.root, className, stripeOnBoard ? css.complete : css.incomplete, appliedClass);

  return (
    <div
      {...setActivePropsMaybe}
      onMouseOver={() => handleHover(id)}
      onMouseOut={() => handleHover(id, false)}
      className={classes}
    >
      <NamedLink
        name={isBusiness ? "ProfilePage" : "ListingPage"}
        params={{ id: isBusiness ? authorId : id, slug }}
      >
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
        >
          {firstImage ? (
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          ) : (
            <img
              className={css.businessDefaultImage}
              alt={"defaultLogo"}
              src={businessDefaultImage}
            />
          )}
        </AspectRatioWrapper>
      </NamedLink>
      <div className={css.info}>
        <div>
          <NamedLink
            name={isBusiness ? "ProfilePage" : "ListingPage"}
            params={{ id: isBusiness ? authorId : id, slug }}
            className={css.title}
          >
            <h3 className={css.title}>
              {companyName || title} ({dbaName})
            </h3>
          </NamedLink>
        </div>
        {city && region
          ? <p className={css.details}>
            <span><FormattedMessage id="ListingCard.city" /> </span>
            {city + ', ' + region}
          </p>
          : null}
        {getBoothFilter && getBoothFilter.length
          ? <div className={css.booths}>
            <FormattedMessage id="ListingCard.booths" />
            <ul className={css.boothsList}>
              {getBoothFilter.map((st, index) => <li key={index}>{st.key == 'other' && boothTypeChilds && boothTypeChilds.boothTypeOther ? boothTypeChilds.boothTypeOther : st.label}</li>)}
            </ul>
          </div>
          : null}
        <p className={css.details}>
          <span><FormattedMessage id="ListingCard.rating" /> </span>
          {ratings >= 1 ? ratings + ' Star' : '5 Stars'} {totalRatings ? totalRatings > 1 ? ('(' + totalRatings + ' Reviews)') : ('(' + totalRatings + ' Review)') : null}
        </p>
        <p className={css.details}>
          <span><FormattedMessage id="ListingCard.rfpsCreated" /> </span>
          {rfpBids}
        </p>
        <p className={css.details}>
          <span><FormattedMessage id="ListingCard.rfpsBidOn" /> </span>{acceptedBids}
        </p>
        <p className={css.details}>
          <span><FormattedMessage id="ListingCard.memberSince" /> </span>
          {moment(createdAt).format('MMM DD, YYYY')}
        </p>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
