import pick from 'lodash/pick';
import {
  createAlgoliaData,
  crudTransactions,
  initiatePrivileged,
  onUpdateEventBidListing,
  transitionPrivileged,
  updateAlgoliaData,
} from '../../util/api';
import { denormalisedResponseEntities, ensureOwnListing, getDistanceOfUser } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { types as sdkTypes } from '../../util/sdkLoader';
import { EVENT_BID_CONFIRMED } from '../../util/types';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const BID_INITIATE_ORDER_REQUEST = 'app/CheckoutPage/BID_INITIATE_ORDER_REQUEST';
export const BID_INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/BID_INITIATE_ORDER_SUCCESS';
export const BID_INITIATE_ORDER_ERROR = 'app/CheckoutPage/BID_INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/CheckoutPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/CheckoutPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/CheckoutPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const INITIATE_INQUIRY_REQUEST = 'app/CheckoutPage/INITIATE_INQUIRY_REQUEST';
export const INITIATE_INQUIRY_SUCCESS = 'app/CheckoutPage/INITIATE_INQUIRY_SUCCESS';
export const INITIATE_INQUIRY_ERROR = 'app/CheckoutPage/INITIATE_INQUIRY_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  initiateInquiryInProgress: false,
  initiateInquiryError: null,
  submitBidInProgress: false,
  submitBidData: null,
  submitBidError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case BID_INITIATE_ORDER_REQUEST:
      return { ...state, submitBidInProgress: true, submitBidError: null };
    case BID_INITIATE_ORDER_SUCCESS:
      return { ...state, submitBidData: payload, submitBidInProgress: false, submitBidError: null };
    case BID_INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, submitBidError: payload, submitBidInProgress: false };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case INITIATE_INQUIRY_REQUEST:
      return { ...state, initiateInquiryInProgress: true, initiateInquiryError: null };
    case INITIATE_INQUIRY_SUCCESS:
      return { ...state, initiateInquiryInProgress: false };
    case INITIATE_INQUIRY_ERROR:
      return { ...state, initiateInquiryInProgress: false, initiateInquiryError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const bidInitiateOrderRequest = () => ({ type: BID_INITIATE_ORDER_REQUEST });

const bidInitiateOrderSuccess = order => ({
  type: BID_INITIATE_ORDER_SUCCESS,
  payload: order,
});

const submitBidError = e => ({
  type: BID_INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const initiateInquiryRequest = () => ({ type: INITIATE_INQUIRY_REQUEST });
export const initiateInquirySuccess = () => ({ type: INITIATE_INQUIRY_SUCCESS });
export const initiateInquiryError = e => ({
  type: INITIATE_INQUIRY_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity, bookingDates, acceptBid,
    bidData, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = deliveryMethod ? { deliveryMethod, acceptBid, bidData } : { acceptBid, bidData };

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition: transitionName,
      params: transitionParams,
    }
    : {
      processAlias,
      transition: transitionName,
      params: transitionParams,
    };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = (transactionId, transitionName, transitionParams = {}) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: transitionParams,
  };
  const queryParams = {
    include: ['booking', 'provider', 'customer'],
    expand: true,
  };

  return sdk.transactions
    .transition(bodyParams, queryParams)
    .then(res => {
      const entities = denormalisedResponseEntities(res);
      const order = entities[0];
      try {
        const { previousTransactionId: transactionId, eventListingId } = order.attributes.protectedData || {};
        // 65e05141-5221-4793-9b03-239bc0d051fa
        // const prevTransctionUpdate = 
        sdk.transactions.transition({
          id: new UUID(transactionId),
          transition: "transition/accept-bid",
          params: {
            protectedData: {
              paymentTransactionId: order.id.uuid,
            }
          }
        });

        // const updateListing = 
        sdk.ownListings.update({
          id: new UUID(eventListingId),
          publicData: {
            eventBid: EVENT_BID_CONFIRMED,
            defaultAcceptBid: "default-accept-bid",
            paymentTransactionId: order.id.uuid,
            bidTransactionId: transactionId,
          },
          privateData: {
            acceptedBidTransactionId: order.id.uuid,
            bidTransactionId: transactionId
          }
        });

        const { customer, provider } = order || {};
        const { uuid: customerId } = (customer && customer.id) || {};
        const { uuid: providerId } = (provider && provider.id) || {};

        crudTransactions({
          id: transactionId,
          action: 'update',
          transaction: {
            status: 'accepted',
            acceptedTxId: order.id.uuid,
          },
        });
        updateAlgoliaData({
          providerId,
          customerId,
          eventListingId,
          status: 'accepted',
          bidTxId: transactionId,
          objectID: eventListingId,
          acceptedTxId: order.id.uuid,
          indexName: process.env.REACT_APP_ALGOLIA_TRANSACTIONS_INDEX,
        });
      } catch (e) {
        console.error(e, '&&& &&& => e');
      }
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate transaction against default-inquiry process
 * Note: At this point inquiry transition is made directly against Marketplace API.
 *       So, client app's server is not involved here unlike with transitions including payments.
 *
 * @param {*} inquiryParams contains listingId and protectedData
 * @param {*} processAlias 'default-inquiry/release-1'
 * @param {*} transitionName 'transition/inquire-without-payment'
 * @returns
 */
export const initiateInquiryWithoutPayment = (inquiryParams, processAlias, transitionName) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateInquiryRequest());

  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(initiateInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const bodyParams = {
    transition: transitionName,
    processAlias,
    params: inquiryParams,
  };
  const queryParams = {
    include: ['provider'],
    expand: true,
  };

  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then(response => {
      const transactionId = response.data.data.id;
      dispatch(initiateInquirySuccess());
      return transactionId;
    })
    .catch(e => {
      dispatch(initiateInquiryError(storableError(e)));
      throw e;
    });
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity, bookingDates, acceptBid,
    bidData, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = deliveryMethod ? { deliveryMethod, acceptBid, bidData } : { acceptBid, bidData };
  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition: transitionName,
      params: transitionParams,
    }
    : {
      processAlias,
      transition: transitionName,
      params: transitionParams,
    };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const bidIntiateOrder = (orderParams, processAlias, requestTransition) => (dispatch, getState, sdk) => {
  try {
    dispatch(bidInitiateOrderRequest());

    const quantity = 1;
    const { eventListing, businessListing, eventListingId, businessListingId, eventStartDateUnix, eventEndDateUnix, bidData, ...rest } = orderParams;

    const { adminProviderCommissionMaybe = false, adminCustomerCommisionMaybe = false } = bidData;

    const bodyParams =
    {
      processAlias,
      transition: requestTransition,
      params: {
        stockReservationQuantity: quantity,
        listingId: eventListingId,
        protectedData: {
          ...rest,
          eventListing, businessListing, eventListingId, businessListingId, eventStartDateUnix, eventEndDateUnix,
          bid: {
            price: bidData.offer.amount,
            currency: bidData.offer.currency,
            note: (bidData?.note || ""),
            tax: (bidData?.tax?.amount || 0),
            adminCustomerCommisionMaybe,
            adminProviderCommissionMaybe
          }
        },
      },
    };

    const queryParams = {
      include: ['booking', 'customer', 'provider', 'listing'],
      expand: true,
    };

    const handleError = e => {
      dispatch(submitBidError(storableError(e)));
      const transactionIdMaybe = {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        quantityMaybe: quantity,
        ...orderData,
      });
      throw e;
    };

    const orderData = { quantity, bidInitiate: true, bidData: { ...bidData, tax: bidData?.tax?.amount } };

    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(res => {
        const entities = denormalisedResponseEntities(res);
        const order = entities[0];
        const { attributes, customer, provider, listing } = order || {};
        const { payinTotal, protectedData, metadata } = attributes || {};
        const { note, tax, price, currency } = { ...protectedData.bid, ...metadata };
        const { uuid: providerId } = (customer && customer.id) || {};
        const { uuid: customerId } = (provider && provider.id) || {};

        const { city, venueAddress } = (listing && listing.id && listing.attributes.publicData) || {};
        const bidTotalAmount = ((bidData.offer.amount) + (bidData.tax && bidData.tax.amount ? bidData.tax.amount : 0));

        const algoliaObject = {
          city,
          customerId,
          providerId,
          eventListingId,
          status: 'bid',
          acceptedTxId: '',
          bidTxId: order.id.uuid,
          note, tax, price, currency,
          bidPrice: bidTotalAmount,
        };

        if (venueAddress && venueAddress.selectedPlace && venueAddress.selectedPlace.origin) {
          const distancePromisify = [], serviceRadiuses = [];
          const { publicData } = (providerId && customer.attributes.profile) || {};
          const { zipCodeRadius } = publicData || {};
          if (zipCodeRadius && zipCodeRadius.length) {
            zipCodeRadius.map(zipCode => {
              const { origin, primaryZip, serviceRadius } = zipCode;

              if (origin && origin.lat) {
                serviceRadiuses.push({ zipCode: primaryZip, id: providerId, serviceRadius });
                distancePromisify.push(getDistanceOfUser({
                  customerOrigin: origin,
                  zipCode: primaryZip,
                  id: providerId,
                  listingOrigin: venueAddress.selectedPlace.origin,
                }));
              }
            });
          }

          if (distancePromisify && distancePromisify.length) {
            Promise.all(distancePromisify)
              .then(distancePromisified => {
                if (distancePromisified && distancePromisified.length) {
                  distancePromisified
                    .sort((a, b) => a.distanceMeter - b.distanceMeter)
                    .filter(p => {
                      const index = serviceRadiuses.findIndex(sr => sr.id == p.id && sr.zipCode == p.zipCode && (p.distanceMeter == 0 || (p.distanceMeter && sr.serviceRadius && sr.serviceRadius >= p.distanceMeter)));
                      if (index > -1) {
                        Object.assign(algoliaObject, {
                          distanceFromCustomer: (p.distanceMile ? p.distanceMile : 0)
                        });
                        return p;
                      }
                    });
                }
                crudTransactions({
                  action: 'create',
                  transaction: {
                    id: order.id.uuid,
                    ...algoliaObject
                  },
                });
                createAlgoliaData({
                  ...algoliaObject,
                  objectID: eventListingId,
                  indexName: process.env.REACT_APP_ALGOLIA_TRANSACTIONS_INDEX,
                });
              });
          }
        } else {
          crudTransactions({
            action: 'create',
            transaction: {
              id: order.id.uuid,
              ...algoliaObject
            },
          });
          createAlgoliaData({
            ...algoliaObject,
            objectID: eventListingId,
            indexName: process.env.REACT_APP_ALGOLIA_TRANSACTIONS_INDEX,
          });
        }

        // Increase the bid on RFP count in the provider business listing
        const { currentUser } = getState().user;
        const { protectedData: uProtectedData } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
        const { businessListingId = '', } = uProtectedData || {};
        const ref = { id: new UUID(businessListingId), type: 'ownListing' };
        const businessListings = getMarketplaceEntities(getState(), [ref]);
        const businessListing = businessListings.length ? ensureOwnListing(businessListings[0]) : ensureOwnListing({});
        if (businessListing && businessListing.id) {
          const { bidOnRFPs = 0, } = businessListing.attributes.publicData || {};
          sdk.ownListings.update({
            publicData: {
              bidOnRFPs: (bidOnRFPs + 1)
            }
          });
        }

        onUpdateEventBidListing({
          eventListingId,
          transactionId: order.id.uuid,
        });
        dispatch(bidInitiateOrderSuccess(order));
        return res;
      })
      .catch(handleError);
  }
  catch (e) {
    return dispatch(submitBidError(storableError(e)));
  }
};
