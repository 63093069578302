import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { crudCategories } from '../../util/api';
export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';

// ================ Action types ================ //

export const FETCH_CATEGORIES_REQUEST = 'app/SignUpStepsPage/FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'app/SignUpStepsPage/FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'app/SignUpStepsPage/FETCH_CATEGORIES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchError: null,
  pagination: null,
  categories: [],
};

export default function SignUpStepsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CATEGORIES_REQUEST:
      return { ...state, fetchInProgress: true, fetchError: null };
    case FETCH_CATEGORIES_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        categories: payload.data,
        pagination: payload.meta,
      };
    }
    case FETCH_CATEGORIES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchRequest = () => ({ type: FETCH_CATEGORIES_REQUEST });
const fetchSuccess = response => ({
  type: FETCH_CATEGORIES_SUCCESS,
  payload: response,
});
const fetchError = e => ({
  type: FETCH_CATEGORIES_ERROR,
  error: true,
  payload: e,
});

export const getCategories = () => (dispatch) => {
  dispatch(fetchRequest());

  crudCategories({ query: { isActive: '1' } })
    .then(res => dispatch(fetchSuccess(res)))
    .catch(err => dispatch(fetchError(err)))
}

export const loadData = (params, search) => dispatch => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
  };
  return Promise.all([dispatch(fetchPageAssets(pageAsset, true)), dispatch(getCategories())]);
};
