import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const UPLOAD_ICON = "uploadIcon"
const MINUS_ICON = "minusIcon"
const PLUS_ICON = "plusIcon"
const DELETE_ICON = "deleteIcon"
const CLOSE_ICON = "closeIcon"
const ARROW_DOWN_ICON = "IconArrowDown";
const INFO_ICON = "IconInfo";
const ICON_COLLAPSE = "IconCollapse";
const ICON_EXPAND = "IconExpand";
// import css from './IconCollection.module.css';

const IconCollection = props => {
  const { className, rootClassName, icon } = props;


  switch (icon) {
    case UPLOAD_ICON:
      return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.4168 19.3333V6.42917L7.30016 10.5458L5.0835 8.25L13.0002 0.333336L20.9168 8.25L18.7002 10.5458L14.5835 6.42917V19.3333H11.4168ZM3.50016 25.6667C2.62933 25.6667 1.88411 25.3569 1.2645 24.7373C0.644885 24.1176 0.334552 23.3719 0.333496 22.5V17.75H3.50016V22.5H22.5002V17.75H25.6668V22.5C25.6668 23.3708 25.357 24.1166 24.7374 24.7373C24.1178 25.3579 23.3721 25.6677 22.5002 25.6667H3.50016Z" fill="black" />
        </svg>
      );
    case MINUS_ICON:
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.66667 10C1.66667 5.39751 5.3975 1.66667 10 1.66667C14.6025 1.66667 18.3333 5.39751 18.3333 10C18.3333 14.6025 14.6025 18.3333 10 18.3333C5.3975 18.3333 1.66667 14.6025 1.66667 10ZM10 3.33334C8.23189 3.33334 6.5362 4.03572 5.28595 5.28596C4.03571 6.5362 3.33333 8.23189 3.33333 10C3.33333 11.7681 4.03571 13.4638 5.28595 14.7141C6.5362 15.9643 8.23189 16.6667 10 16.6667C11.7681 16.6667 13.4638 15.9643 14.714 14.7141C15.9643 13.4638 16.6667 11.7681 16.6667 10C16.6667 8.23189 15.9643 6.5362 14.714 5.28596C13.4638 4.03572 11.7681 3.33334 10 3.33334Z" fill="black" />
          <rect x="5" y="9" width="10" height="2" rx="1" fill="black" />
        </svg>

      );
    case PLUS_ICON:
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.66667 10C1.66667 5.39751 5.39751 1.66667 10 1.66667C14.6025 1.66667 18.3333 5.39751 18.3333 10C18.3333 14.6025 14.6025 18.3333 10 18.3333C5.39751 18.3333 1.66667 14.6025 1.66667 10ZM10 3.33334C8.23189 3.33334 6.5362 4.03572 5.28596 5.28596C4.03572 6.5362 3.33334 8.23189 3.33334 10C3.33334 11.7681 4.03572 13.4638 5.28596 14.7141C6.5362 15.9643 8.23189 16.6667 10 16.6667C11.7681 16.6667 13.4638 15.9643 14.7141 14.7141C15.9643 13.4638 16.6667 11.7681 16.6667 10C16.6667 8.23189 15.9643 6.5362 14.7141 5.28596C13.4638 4.03572 11.7681 3.33334 10 3.33334Z" fill="black" />
          <path fillRule="evenodd" clipRule="evenodd" d="M10.8333 5.83333C10.8333 5.61232 10.7455 5.40036 10.5893 5.24408C10.433 5.0878 10.221 5 10 5C9.77899 5 9.56702 5.0878 9.41074 5.24408C9.25446 5.40036 9.16667 5.61232 9.16667 5.83333V9.16667H5.83333C5.61232 9.16667 5.40036 9.25446 5.24408 9.41074C5.0878 9.56702 5 9.77899 5 10C5 10.221 5.0878 10.433 5.24408 10.5893C5.40036 10.7455 5.61232 10.8333 5.83333 10.8333H9.16667V14.1667C9.16667 14.3877 9.25446 14.5996 9.41074 14.7559C9.56702 14.9122 9.77899 15 10 15C10.221 15 10.433 14.9122 10.5893 14.7559C10.7455 14.5996 10.8333 14.3877 10.8333 14.1667V10.8333H14.1667C14.3877 10.8333 14.5996 10.7455 14.7559 10.5893C14.9122 10.433 15 10.221 15 10C15 9.77899 14.9122 9.56702 14.7559 9.41074C14.5996 9.25446 14.3877 9.16667 14.1667 9.16667H10.8333V5.83333Z" fill="black" />
        </svg>
      );
    case DELETE_ICON:
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.19667 20.0217 5.00067 19.5507 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.0217 20.805 17.5507 21.0007 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="#4A4A4A" />
        </svg>
      );

    case CLOSE_ICON:
      return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.6875 2.875H4.3125C3.93125 2.875 3.56562 3.02645 3.29603 3.29603C3.02645 3.56562 2.875 3.93125 2.875 4.3125V18.6875C2.875 19.0687 3.02645 19.4344 3.29603 19.704C3.56562 19.9735 3.93125 20.125 4.3125 20.125H18.6875C19.0687 20.125 19.4344 19.9735 19.704 19.704C19.9735 19.4344 20.125 19.0687 20.125 18.6875V4.3125C20.125 3.93125 19.9735 3.56562 19.704 3.29603C19.4344 3.02645 19.0687 2.875 18.6875 2.875ZM16.321 15.304C16.3878 15.3708 16.4408 15.45 16.4769 15.5373C16.513 15.6245 16.5317 15.7181 16.5317 15.8125C16.5317 15.9069 16.513 16.0005 16.4769 16.0877C16.4408 16.175 16.3878 16.2542 16.321 16.321C16.2542 16.3878 16.175 16.4408 16.0877 16.4769C16.0005 16.513 15.9069 16.5317 15.8125 16.5317C15.7181 16.5317 15.6245 16.513 15.5373 16.4769C15.45 16.4408 15.3708 16.3878 15.304 16.321L11.5 12.5161L7.69602 16.321C7.56115 16.4559 7.37823 16.5317 7.1875 16.5317C6.99677 16.5317 6.81385 16.4559 6.67898 16.321C6.54412 16.1861 6.46835 16.0032 6.46835 15.8125C6.46835 15.6218 6.54412 15.4389 6.67898 15.304L10.4839 11.5L6.67898 7.69602C6.54412 7.56115 6.46835 7.37823 6.46835 7.1875C6.46835 6.99677 6.54412 6.81385 6.67898 6.67898C6.81385 6.54412 6.99677 6.46835 7.1875 6.46835C7.37823 6.46835 7.56115 6.54412 7.69602 6.67898L11.5 10.4839L15.304 6.67898C15.4389 6.54412 15.6218 6.46835 15.8125 6.46835C16.0032 6.46835 16.1861 6.54412 16.321 6.67898C16.4559 6.81385 16.5317 6.99677 16.5317 7.1875C16.5317 7.37823 16.4559 7.56115 16.321 7.69602L12.5161 11.5L16.321 15.304Z" fill="black" />
        </svg>
      );
    case ARROW_DOWN_ICON:
      return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.5 8.80002L0 2.93335H11L5.5 8.80002Z" fill="#0E1319" />
        </svg>
      );
    case INFO_ICON:
      return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_1418_1612" style={{ maskType: "" }} maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="23">
            <path d="M11.5 21.0834C12.7587 21.0851 14.0054 20.8379 15.1683 20.3562C16.3312 19.8745 17.3875 19.1677 18.2763 18.2765C19.1676 17.3876 19.8744 16.3313 20.3561 15.1684C20.8378 14.0055 21.0849 12.7588 21.0833 11.5001C21.0849 10.2414 20.8377 8.99471 20.356 7.83179C19.8743 6.66888 19.1676 5.61262 18.2763 4.72371C17.3875 3.83248 16.3312 3.12569 15.1683 2.64398C14.0054 2.16227 12.7587 1.91513 11.5 1.91676C10.2412 1.91515 8.99459 2.16231 7.83167 2.64402C6.66876 3.12573 5.6125 3.8325 4.72359 4.72371C3.83238 5.61262 3.12561 6.66888 2.6439 7.83179C2.16218 8.99471 1.91503 10.2414 1.91663 11.5001C1.915 12.7588 2.16215 14.0055 2.64386 15.1684C3.12557 16.3313 3.83236 17.3876 4.72359 18.2765C5.6125 19.1677 6.66876 19.8745 7.83167 20.3562C8.99459 20.8379 10.2412 21.085 11.5 21.0834Z" fill="white" stroke="white" strokeWidth="2" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4999 17.7293C11.8176 17.7293 12.1223 17.6031 12.347 17.3785C12.5716 17.1538 12.6978 16.8491 12.6978 16.5314C12.6978 16.2137 12.5716 15.909 12.347 15.6844C12.1223 15.4597 11.8176 15.3335 11.4999 15.3335C11.1822 15.3335 10.8775 15.4597 10.6529 15.6844C10.4282 15.909 10.302 16.2137 10.302 16.5314C10.302 16.8491 10.4282 17.1538 10.6529 17.3785C10.8775 17.6031 11.1822 17.7293 11.4999 17.7293Z" fill="black" />
            <path d="M11.5 5.75V13.4167" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </mask>
          <g mask="url(#mask0_1418_1612)">
            <path d="M0 0H23V23H0V0Z" fill="#F7D41B" />
          </g>
        </svg>
      );
    case ICON_COLLAPSE:
      return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M8.47641 12.649C8.8298 13.117 9.39775 13.117 9.75114 12.649L16.9632 3.0495C17.3157 2.57912 17.3157 1.82076 16.9632 1.35278C16.6107 0.882406 16.04 0.882406 15.6884 1.35278L9.11377 10.1027L2.53909 1.35278C2.18661 0.882406 1.61595 0.882406 1.26437 1.35278C0.911878 1.82076 0.911878 2.57912 1.26437 3.0495L8.47641 12.649Z" fill="#7C3AED" stroke="#7C3AED" />
        </svg>
      );
    case ICON_EXPAND:
      return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.79347 1.38276C9.44178 0.913499 8.87384 0.911437 8.51875 1.37813L1.27191 10.9514C0.917712 11.4205 0.914958 12.1789 1.26575 12.6481C1.61653 13.1198 2.18717 13.1218 2.54047 12.6527L9.14687 3.9267L15.6897 12.7005C16.0405 13.1721 16.6112 13.1742 16.9645 12.7051C17.3186 12.2384 17.3214 11.48 16.9706 11.0084L9.79347 1.38276Z" fill="#7C3AED" stroke="#7C3AED" />
        </svg>
      );
    default:
      return (
        <>
        </>
      );
  }
};

IconCollection.defaultProps = {
  className: null,
  rootClassName: null,
  brand: 'default',
};

IconCollection.propTypes = {
  className: string,
  rootClassName: string,
  brand: string,
};

export default IconCollection;
